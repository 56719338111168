<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Send Process Email</h2>
  </div>

  <mat-dialog-content>
    <form id="processEmailForm" #processEmailForm="ngForm" (ngSubmit)="sendEmail(processEmailForm)">
      <app-multi-autocomplete name="recipients"
                              [label]="'Recipient(s)'"
                              [placeholder]="'Select Users'"
                              [options]="users"
                              [(ngModel)]="processEmail.recipients"
                              [isDisabled]="isBusy">
      </app-multi-autocomplete>

      <mat-form-field color="accent">
        <mat-label>Subject</mat-label>
        <input matInput
               [disabled]="isBusy"
               placeholder="Enter Subject..."
               name="subject"
               [(ngModel)]="processEmail.subject"
               #subject="ngModel"
               required>
        <mat-error *ngIf="subject.invalid">
          Subject is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>Message</mat-label>
        <textarea matInput
                  [disabled]="isBusy"
                  rows="5"
                  placeholder="Enter Message..."
                  name="message"
                  [(ngModel)]="processEmail.message"
                  #message="ngModel"
                  required></textarea>
        <mat-error *ngIf="message.invalid">
          Message is required.
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button"
            class="button"
            [disabled]="isBusy"
            mat-raised-button
            (click)="close()">
      Cancel
    </button>
    <button type="submit"
            form="processEmailForm"
            mat-raised-button
            [disabled]="isBusy"
            class="button accent-button"
            color="accent">
      Send Message
    </button>
  </mat-dialog-actions>
</section>
