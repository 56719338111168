import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ProcessModel } from '../../../models/process-model';
import { ProcessService } from '../../../services/process.service';
import { ProcessTaskModel } from '../../../models/process-task-model';
import { ProcessTaskService } from '../../../services/process-task.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm, FormsModule, FormGroupDirective } from '@angular/forms';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProcessTaskDialogComponent } from './process-task-dialog/process-task-dialog.component';
import { ProcessTaskBranchDialogComponent } from './process-task-branch-dialog/process-task-branch-dialog.component';
import { RoleModel } from '../../../models/role-model';
import { RoleService } from '../../../services/role.service';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import jsPDF from 'jspdf'
import { InfoDialogComponent } from '../../../shared/dialogs/info-dialog/info-dialog.component';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTree, MatTreeNodeDef, MatTreeNode, MatNestedTreeNode, MatTreeNodeToggle, MatTreeNodeOutlet } from '@angular/material/tree';
import { TreeNodeModel } from '../../../models/tree-node-model';
import { TitleService } from '../../../services/title.service';
import { ProcessRevisionListDialogComponent } from './process-revision-list-dialog/process-revision-list-dialog.component';
import { RoleTitleUtility } from '../../../utility/role-title-utility';
import { ProcessStandardListDialogComponent } from './process-standard-list-dialog/process-standard-list-dialog.component';
import { ProcessCustomListDialogComponent } from './process-custom-list-dialog/process-custom-list-dialog.component';
import { CategoryModel } from '../../../models/category-model';
import { CategoryService } from '../../../services/category.service';
import { ProcessTaskDocumentModel } from '../../../models/process-task-document-model';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { FileDocumentService } from '../../../services/file-document-service';
import { ProcessResourceListDialogComponent } from './process-resources-list-dialog/process-resource-list-dialog.component';
import { ProcessExportModel } from '../../../models/process-export-model';
import { ProcessCopyDialogComponent } from './process-copy-dialog/process-copy-dialog.component';
import { ProcessGroupModel } from '../../../models/process-group-model';
import { DocumentModel } from '../../../models/document-model';
import { DocumentService } from '../../../services/document.service';
import { DocumentDialogComponent } from '../../document/document-dialog/document-dialog.component';
import { ValidationService } from '../../../services/validation-service';
// @ts-ignore
import * as html2canvas from "../../../../assets/js/html2canvas.js";
import { CustomService } from '../../../services/custom.service';
import { ResourceService } from '../../../services/resource.service';
import { StandardService } from '../../../services/standard.service';
import { CustomModel } from '../../../models/custom-model';
import { CustomDialogComponent } from '../../custom-lists/custom-dialog/custom-dialog.component';
import { ProcessTaskCustomModel } from '../../../models/process-task-custom-model';
import { ResourceModel } from '../../../models/resource-model';
import { ResourceDialogComponent } from '../../resources/resource-dialog/resource-dialog.component';
import { ProcessTaskResourceModel } from '../../../models/process-task-resource-model';
import { StandardSectionModel } from '../../../models/standard-section-model';
import { StandardSectionDialogComponent } from '../../standard/standard-section-dialog/standard-section-dialog.component';
import { ProcessTaskStandardSectionModel } from '../../../models/process-task-standard-section-model';
import { ProcessAuditListDialogComponent } from '../process-audit/process-audit-list-dialog.component/process-audit-list-dialog.component';
import { environment } from '../../../../environments/environment';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { MatDrawerContainer, MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatError, MatHint, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AutocompleteComponent } from '../../../shared/autocomplete/autocomplete.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MultiAutocompleteComponent } from '../../../shared/multi-autocomplete/multi-autocomplete.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { DatePickerComponent } from '../../../shared/date-picker/date-picker.component';
import { ProcessEmailDialogComponent } from './process-email-dialog/process-email-dialog.component';
import { FormUtility } from '../../../utility/form-utility';
import { CdkDragDrop, moveItemInArray, CdkDrag, CdkDropList, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ProcessEmailModel } from '../../../models/process-email-model';

@Component({
    selector: 'app-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardContent, NgIf, LoadingSpinnerComponent, MatDrawerContainer, MatDrawer, MatTree, MatTreeNodeDef,
      MatTreeNode, MatButton, MatIcon, MatNestedTreeNode, MatTreeNodeToggle, MatTreeNodeOutlet, MatDrawerContent, FormsModule,
      MatFormField, MatLabel, MatInput, MatError, AutocompleteComponent, MatTooltip, MultiAutocompleteComponent, MatHint, MatSuffix,
      MatCheckbox, NgFor, MatIconButton, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgClass,
      RouterLink, MatFooterCellDef, MatFooterCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow,
      DatePipe, DatePickerComponent, CdkDrag, CdkDropList, CdkDragHandle]
})
export class ProcessComponent implements OnInit {
  private route = inject(ActivatedRoute);

  public process: ProcessModel;
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public roles: Array<RoleModel> = [];
  public isEditMode: boolean = false;
  public allRoles: Array<RoleModel> = [];
  public categories: Array<CategoryModel> = [];

  public columns: string[] = [];
  public tableData = new MatTableDataSource<ProcessTaskModel>([]);
  public movedRowId: number | null = null;
  public showRowHighlight: boolean = false;
  public highlightTimeoutIds: Array<number> = [];
  public dragDisabled = true;

  public processTreeControl: NestedTreeControl<TreeNodeModel>;
  public processTreeDataSource = new MatTreeNestedDataSource<TreeNodeModel>();
  public currentNode: TreeNodeModel | undefined;

  public readonly printableSectionOneId: string = "printable-section-one";
  public readonly printableSectionTwoId: string = "printable-section-two";

  @ViewChild('processGroupForm') processGroupForm: FormGroupDirective;

  constructor(private titleService: TitleService,
    private processService: ProcessService,
    private roleService: RoleService,
    private processTaskService: ProcessTaskService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private categoryService: CategoryService,
    private fileDocumentService: FileDocumentService,
    private documentService: DocumentService,
    private validationService: ValidationService,
    private customService: CustomService,
    private resourceService: ResourceService,
    private standardService: StandardService) {
    this.activatedRoute.paramMap.subscribe(() => {
      this.ngOnInit();
    });
  }

  // TODO TEMP
  public isAuditsEnabled(): boolean {
    return environment.instance == "test" ||
      environment.instance == "dev" ||
      this.authService.currentUser?.companyId == 1 ||
      this.authService.currentUser?.companyId == 4;
  }

  public ngOnInit(): void {
    this.isLoading = true;
    const processGroupId = Number(this.route.snapshot.paramMap.get('groupId'));
    const processId = Number(this.route.snapshot.paramMap.get('id'));

    if (this.canEdit()) {
      this.isEditMode = true;
    }

    let sources: Array<Observable<any>> = [
      this.processService.getProcessesTree(),
      this.roleService.getRoles(),
      this.categoryService.getCategories(),
    ];

    if (processId) {
      const existingProcessSources: Array<Observable<any>> = [
        this.processService.getProcess(processId),
        this.roleService.getRolesForProcess(processId),
      ];
      sources = sources.concat(existingProcessSources);
    }

      forkJoin(sources).subscribe({
        next: (response) => {
          if (processId) {
            this.process = response[3] as ProcessModel;
            this.roles = response[4] as RoleModel[];
            this.titleService.setTitle(this.process.title);
            this.sortProcessTasksAndRoles();
            this.refreshTableData();
          } else {
            this.process = new ProcessModel();
            this.process.processGroupId = processGroupId;
            this.isEditMode = true;
            this.titleService.setTitle("New Process");
          }
          this.initialiseTreeControl(response[0] as TreeNodeModel[]);
          this.allRoles = response[1] as RoleModel[];
          this.categories = response[2] as CategoryModel[];
          this.isLoading = false;
        },
      });
  }

  public get isNew(): boolean {
    return this.process.processId == null;
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public hasNoUsersAssigned(roleId: number | null): boolean {
    if (roleId === null) {
      return false;
    }

    const role = this.allRoles.find(i => i.roleId === roleId);
    return role ? role.userRoles.length === 0 : true;
  }

  public save(form: NgForm): void {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.processService.saveProcess(this.process).subscribe({
        next: (response: ProcessModel) => {
          this.process = response;
          this.titleService.setTitle(this.process.title);
          this.toastr.success("Process saved");
          this.sortProcessTasksAndRoles();
          this.refreshTableData();
          FormUtility.markAsPristine(form);
          this.isBusy = false;
        },
        error: () => {
          this.isBusy = false;
        }
      });
    } else {
      this.toastr.error("One or more validation errors occurred.")
    }
  }

  public addProcessTask(): void {
    if (!this.canEdit() || this.isBusy) {
      return;
    }

    let processTask = new ProcessTaskModel();
    processTask.processId = this.process.processId;

    this.openProcessTaskDialog(processTask);
  }

  public editProcessTask(processTask: ProcessTaskModel): void {
    if (this.isBusy) {
      return;
    }

    this.openProcessTaskDialog(processTask);
  }

  private openProcessTaskDialog(processTask: ProcessTaskModel): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...processTask,
      }
    };

    const dialogRef = this.dialog.open(ProcessTaskDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessTaskModel) => {
        if (result) {
          this.processService.getProcess(this.process.processId).subscribe({
            next: (response: ProcessModel) => {
              this.process = response;
              this.titleService.setTitle(this.process.title);
              this.getRolesAndSetTableData();
            }
          });
        }
      }
    });
  }

  public deleteProcessTask(processTask: ProcessTaskModel): void {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete "${processTask.title}"?`)) {
      return;
    }

    this.processTaskService.deleteProcessTask(processTask.processTaskId).subscribe({
      next: () => {
        this.process.processTasks.splice(this.process.processTasks.indexOf(processTask), 1);
        this.refreshTableData();
        if (processTask.title == null) {
          this.toastr.success(`Task deleted`)
        } else {
          this.toastr.success(`Task ${processTask.title} deleted`);
        }
        this.processService.getProcess(this.process.processId).subscribe({
          next: (response: ProcessModel) => {
            this.process = response;
            this.titleService.setTitle(this.process.title);
            this.getRolesAndSetTableData();
          }
        });
      }
    });
  }

  public moveProcessTaskUp(processTask: ProcessTaskModel): void {
    if (!this.canEdit() || this.isFirstProcessTask(processTask)) {
      return;
    }

    this.isBusy = true;
    let index = this.process.processTasks.indexOf(processTask);

    [this.process.processTasks[index], this.process.processTasks[index - 1]] = [this.process.processTasks[index - 1], this.process.processTasks[index]];
    this.updateProcessTaskIndexPositions(processTask.processTaskId);
  }

  public moveProcessTaskDown(processTask: ProcessTaskModel): void {
    if (!this.canEdit() || this.isLastProcessTask(processTask)) {
      return;
    }

    this.isBusy = true;
    let index = this.process.processTasks.indexOf(processTask);

    [this.process.processTasks[index], this.process.processTasks[index + 1]] = [this.process.processTasks[index + 1], this.process.processTasks[index]];
    this.updateProcessTaskIndexPositions(processTask.processTaskId);
  }

  public drop(event: CdkDragDrop<ProcessTaskModel[]>) {
    moveItemInArray(this.tableData.data, event.previousIndex, event.currentIndex);
    this.updateProcessTaskIndexPositions(event.item.data.id);
  }

  public isFirstProcessTask(processTask: ProcessTaskModel): boolean {
    return this.process.processTasks.indexOf(processTask) === 0;
  }

  public isLastProcessTask(processTask: ProcessTaskModel): boolean {
    return this.process.processTasks.indexOf(processTask) === this.process.processTasks.length - 1;
  }

  private updateProcessTaskIndexPositions(processTaskId: number): void {
    this.process.processTasks.forEach((processTask, index) => {
      processTask.index = index;
    });

    this.sortProcessTasksAndRoles();

    this.processTaskService.updateProcessTasksIndexPositions(this.process.processTasks).subscribe({
      next: (response: Array<ProcessTaskModel>) => {
        this.process.processTasks = response;
        this.updateShowRowHighlight(processTaskId);
        this.refreshTableData();
        this.isBusy = false;
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  private sortProcessTasksAndRoles(): void {
    this.process.processTasks.sort((a, b) => a.index - b.index);

    var responsibleRoles = this.process.processTasks.filter(i => i.responsibleRoleId != null).flatMap(i => i.responsibleRoleId);
    var accountableRoles = this.process.processTasks.filter(i => i.accountableRoles.length > 0).flatMap(i => i.accountableRoles).map(i => i.roleId);
    var consultedRoles = this.process.processTasks.filter(i => i.accountableRoles.length > 0).flatMap(i => i.consultedRoles).map(i => i.roleId);
    var informedRoles = this.process.processTasks.filter(i => i.accountableRoles.length > 0).flatMap(i => i.informedRoles).map(i => i.roleId);

    const orderedRoles = [... new Set(responsibleRoles.concat(accountableRoles).concat(consultedRoles).concat(informedRoles))];

    const customSort = (roleA: RoleModel, roleB: RoleModel): number => {
      const indexA: number = orderedRoles.indexOf(roleA.roleId);
      const indexB: number = orderedRoles.indexOf(roleB.roleId);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0;
      }
    };

    this.roles.sort(customSort);
  }

  private refreshTableData(): void {
    this.columns = ['sequence', 'title'];

    this.roles.forEach(role => {
      this.columns.push(role.title);
    });

    this.columns.push('description');

    if (this.isEditMode) {
      this.columns.push('controls');
    }

    // TODO
    this.process.processTasks.forEach(processTask => {
      processTask.descriptionFrom = null;
      processTask.descriptionTo = null;
      processTask.descriptionReason = null;
    });

    this.process.processTasks.forEach(processTask => {
      if (processTask.branchToProcessTask) {
        const linkedProcessTask = this.process.processTasks.find(t => t.processTaskId === processTask.branchToProcessTaskId);

        if (linkedProcessTask) {
          processTask.branchToProcessTask.headerSequenceNumber = linkedProcessTask.headerSequenceNumber;
          processTask.branchToProcessTask.subSequenceNumber = linkedProcessTask.subSequenceNumber;

          if (!linkedProcessTask.descriptionFrom) {
            linkedProcessTask.descriptionFrom = `From ${processTask.headerSequenceNumber}.${processTask.subSequenceNumber}`;
          } else {
            linkedProcessTask.descriptionFrom += `, ${processTask.headerSequenceNumber}.${processTask.subSequenceNumber}`;
          }
        }

        processTask.descriptionTo = `Go to ${processTask.branchToProcessTask.headerSequenceNumber}.${processTask.branchToProcessTask.subSequenceNumber}`;

        if (processTask.branchToReason && processTask.branchToReason != "") {
          processTask.descriptionReason = `if ${processTask.branchToReason}`;
        }
      }
    });

    this.tableData.data = this.process.processTasks;
  }

  public branchProcessTask(processTask: ProcessTaskModel) {
    if (!this.canEdit()) {
      return;
    }

    let filteredProcessTasks = this.process.processTasks.filter(i => i.processTaskId !== processTask.processTaskId);

    const dialogConfig: MatDialogConfig = {
      data: {
        processTasks: filteredProcessTasks,
        selectedProcessTask: processTask
      }
    };

    const dialogRef = this.dialog.open(ProcessTaskBranchDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result: ProcessTaskModel) => {
        if (result) {
          this.processService.getProcess(this.process.processId).subscribe({
            next: (response: ProcessModel) => {
              this.process = response;
              this.titleService.setTitle(this.process.title);
              this.sortProcessTasksAndRoles();
              this.refreshTableData();
            }
          });
        }
      }
    });
  }

  public getRoleClass(processTask: ProcessTaskModel, role: RoleModel): string {
    const roleId = role.roleId;
    let roleClass = '';

    if (processTask.responsibleRoleId == roleId) {
      roleClass += 'raci-icon ';
      if (processTask.isMilestone) {
        roleClass += 'milestone-star ';
      } else if (processTask.branchToProcessTask) {
        roleClass += 'branch-to-diamond ';
        if (processTask.index > processTask.branchToProcessTask.index) {
          roleClass += 'flip ';
        }
      } else {
        roleClass += 'responsible-triangle ';
      }
    } else if (processTask.accountableRoles.findIndex(i => i.roleId == roleId) >= 0) {
      roleClass += 'accountable-square raci-square ';
    } else if (processTask.consultedRoles.findIndex(i => i.roleId == roleId) >= 0) {
      roleClass += 'consults-square raci-square ';
    } else if (processTask.informedRoles.findIndex(i => i.roleId == roleId) >= 0) {
      roleClass += 'informed-square raci-square ';
    }

    return roleClass;
  }

  public getRaciTooltip(processTask: ProcessTaskModel, role: RoleModel): string {
    const roleName = role.title;
    const roleId = role.roleId;

    if (processTask.responsibleRoleId == roleId) {
      return "Responsible - " + roleName;
    } else if (processTask.accountableRoles.findIndex(i => i.roleId == roleId) >= 0) {
      return this.authService.currentUser?.companyAccountableText + " - " + roleName;
    } else if (processTask.consultedRoles.findIndex(i => i.roleId == roleId) >= 0) {
      return "Consulted - " + roleName;
    } else if (processTask.informedRoles.findIndex(i => i.roleId == roleId) >= 0) {
      return "Informed - " + roleName;
    } else {
      return "";
    }
  }

  public getRoleTitle(roleId: number | null): string | null {
    return RoleTitleUtility.getRoleTitle(this.allRoles, roleId);
  }

  private getRolesAndSetTableData(): void {
    this.roleService.getRolesForProcess(this.process.processId).subscribe({
      next: (response: Array<RoleModel>) => {
        this.roles = response;
        this.sortProcessTasksAndRoles();
        this.refreshTableData();
      }
    })
  }

  public getSwimlaneColor() {
    var swimlaneColor = this.authService.currentUser?.companySwimLaneColor || '#F5F5F5'

    return swimlaneColor
  }

  public toggleView(): void {
    if (this.isEditMode) {
      this.columns.pop();
    } else {
      this.columns.push('controls');
    }

    this.isEditMode = !this.isEditMode;
  }

  public exportExcel(): void {
    this.isBusy = true;

    const processExportModel = new ProcessExportModel();
    processExportModel.processId = this.process.processId;
    processExportModel.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.processService.exportProcess(processExportModel).subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }

  public export(): void {
    if (this.isEditMode) {
      this.toastr.error("Cannot export while in edit mode.");
      return;
    }

    this.isBusy = true;

    const dialogConfig: MatDialogConfig = {
      data: {
        title: "Process Export",
        message: "Generating export...",
        showCloseButton: false
      }
    };

    this.dialog.open(InfoDialogComponent, dialogConfig).afterOpened().subscribe(() => {
      let htmlElementToPrintOne = document.getElementById(this.printableSectionOneId);
      let htmlElementToPrintTwo = document.getElementById(this.printableSectionTwoId);

      if (!htmlElementToPrintOne || !htmlElementToPrintTwo) {
        this.toastr.error("Error exporting process");
        this.isBusy = false;
        this.dialog.closeAll();
        return;
      }

      let combinedPrintingElement = document.createElement('printing-area');
      combinedPrintingElement.innerHTML = htmlElementToPrintOne.innerHTML + htmlElementToPrintTwo.innerHTML;
      document.body.appendChild(combinedPrintingElement);

      html2canvas(combinedPrintingElement, { scale: 3, imageTimeout: 0 }).then((canvas: any) => {
        const imageWidth = 208;
        const pageHeight = 295;

        const doc = new jsPDF('portrait', 'mm');
        const documentFormat = 'PNG';
        const documentCompression = 'FAST';

        let heightLeft = canvas.height;
        let yOffset = 0;

        const pageCanvas = document.createElement('canvas');
        const pageContext = pageCanvas.getContext('2d');
        pageCanvas.width = canvas.width;

        while (heightLeft > 0) {
          const sliceHeight = Math.min(heightLeft, (pageHeight * canvas.width) / imageWidth);
          pageCanvas.height = sliceHeight;

          pageContext!.clearRect(0, 0, pageCanvas.width, pageCanvas.height);
          pageContext!.drawImage(canvas, 0, yOffset, canvas.width, sliceHeight, 0, 0, pageCanvas.width, sliceHeight);

          const pageImage = pageCanvas.toDataURL('image/png');
          doc.addImage({
            imageData: pageImage,
            format: documentFormat,
            x: 1.25,
            y: 0,
            width: imageWidth,
            height: (sliceHeight * imageWidth) / canvas.width,
            compression: documentCompression,
          });

          heightLeft -= sliceHeight;
          yOffset += sliceHeight;

          if (heightLeft > 0) {
            doc.addPage();
          }
        }

        let title = this.process.title;
        if (this.process.isDraft) {
          title += " - DRAFT";
        }

        doc.save(`${title} ${new Date().toLocaleDateString()}.pdf`);

        document.body.removeChild(combinedPrintingElement);
        this.isBusy = false;
        this.dialog.closeAll();
      });
    });
  }

  private initialiseTreeControl(nodes: Array<TreeNodeModel>): void {
    this.processTreeControl = new NestedTreeControl<TreeNodeModel>(node => node.children);
    this.processTreeDataSource.data = nodes;
    const currentParentNode = this.processTreeDataSource.data.find(i => i.id == this.process.processGroupId);
    if (currentParentNode) {
      this.processTreeControl.expandDescendants(currentParentNode);
      if (currentParentNode.children) {
        this.currentNode = currentParentNode.children.find(i => i.id == this.process.processId);
      }
    }
  }

  public nodeHasChild(_: number, node: TreeNodeModel): boolean {
    return !!node.children && node.children.length > 0;
  }

  public newProcess(node: TreeNodeModel): void {
    if (!this.canEdit() || !node.parentId) {
      return;
    }

    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}`, { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${node.parentId}/0`);
    });
  }

  public openProcess(process: TreeNodeModel): void {
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${process.parentId}/${process.id}`);
  }

  public isActiveNode(node: TreeNodeModel): boolean {
    return !!this.currentNode && this.currentNode.id == node.id && this.currentNode.parentId == node.parentId;
  }

  public openRevisionsDialog(): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...this.process,
      },
      autoFocus: false,
    };

    this.dialog.open(ProcessRevisionListDialogComponent, dialogConfig);
  }

  public openStandardsDialog(): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...this.process,
      },
      autoFocus: false,
    };

    this.dialog.open(ProcessStandardListDialogComponent, dialogConfig);
  }

  public openCustomsDialog(): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...this.process,
      },
      autoFocus: false,
    };

    this.dialog.open(ProcessCustomListDialogComponent, dialogConfig);
  }

  public openResourcesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...this.process,
      },
      autoFocus: false,
    };

    this.dialog.open(ProcessResourceListDialogComponent, dialogConfig);
  }

  public openAuditsDialog(): void {
    // This needs to be visible when creating a new audit so all data is copied across.
    this.process.processTasks.forEach(i => i.showNotes = true);

    const dialogConfig: MatDialogConfig = {
      data: {
        process: this.process,
        roles: this.roles,
        processHasChanges: this.processGroupForm?.dirty ?? false,
      },
      autoFocus: false,
    };

    this.dialog.open(ProcessAuditListDialogComponent, dialogConfig);
  }

  public getRowClass(processTask: ProcessTaskModel): string {
    if (this.movedRowId && processTask.processTaskId == this.movedRowId) {
      this.highlightTimeoutIds.push(window.setTimeout(() => this.showRowHighlight = false, 2000));
      return 'highlight-row';
    }

    return '';
  }

  private updateShowRowHighlight(processTaskId: number): void {
    if (this.highlightTimeoutIds.length > 0) {
      this.highlightTimeoutIds.forEach(i => window.clearTimeout(i));
    }
    this.showRowHighlight = true;
    this.movedRowId = processTaskId;
  }

  public downloadDocument(document: ProcessTaskDocumentModel): void {
    if (document.fileDocument) {
      this.fileDocumentService.downloadFileDocument(document.fileDocument).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public generateChecklistDocument(): void {
    this.isBusy = true;
    this.processService.generateChecklistDocument(this.process.processId).subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }

  public copyProcess(): void {
    if (!this.canEdit()) {
      return;
    }

    const selectedProcessGroupIds: Array<ProcessGroupModel> = [];

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedProcessGroupIds,
      },
      width: "60%",
      autoFocus: false,
    };

    this.dialog.open(ProcessCopyDialogComponent, dialogConfig).afterClosed().subscribe({
      next: (result: number | null) => {
        if (result) {
          this.isBusy = true;
          this.processService.copyProcess(this.process.processId, result).subscribe({
            next: (copyModel: ProcessModel) => {
              this.isBusy = false;
              this.toastr.success("Process Copied");
              this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${copyModel.processGroupId}/${copyModel.processId}`);
            },
            error: () => {
              this.isBusy = false;
            }
          });
        }
      }
    });
  }

  public openDocument(documentId: number): void {
    this.documentService.getDocumentById(documentId).subscribe({
      next: (document: DocumentModel) => {
        const dialogConfig: MatDialogConfig = {
          data: { ...document }
        };

        const dialogRef = this.dialog.open(DocumentDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
          next: (result: DocumentModel | null) => {
            if (result) {
              let documentsToUpdate: ProcessTaskDocumentModel[] = this.process.processTasks.flatMap(i => i.processTaskDocuments).filter(i => i.documentId == documentId);
              documentsToUpdate.forEach(i => {
                i.documentName = result.documentName;
                i.documentLink = result.link;
                i.fileDocument = result.fileDocument;
              });
            }
          }
        });
      }
    });
  }

  public openCustom(customId: number): void {
    this.customService.getCustomById(customId).subscribe({
      next: (custom: CustomModel) => {
        const dialogConfig: MatDialogConfig = {
          data: { ...custom }
        };

        const dialogRef = this.dialog.open(CustomDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
          next: (result: CustomModel | null) => {
            if (result) {
              let customsToUpdate: ProcessTaskCustomModel[] = this.process.processTasks.flatMap(i => i.processTaskCustoms).filter(i => i.customId == customId);
              customsToUpdate.forEach(i => {
                i.customDisplayName = result.title
              });
            }
          }
        });
      }
    });
  }

  public openResource(resourceId: number): void {
    this.resourceService.getResourceById(resourceId).subscribe({
      next: (resource: ResourceModel) => {
        const dialogConfig: MatDialogConfig = {
          data: { ...resource }
        };

        const dialogRef = this.dialog.open(ResourceDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
          next: (result: ResourceModel | null) => {
            if (result) {
              let resourcesToUpdate: ProcessTaskResourceModel[] = this.process.processTasks.flatMap(i => i.processTaskResources).filter(i => i.resourceId == resourceId);
              resourcesToUpdate.forEach(i => {
                i.resourceDisplayName = result.title
              });
            }
          }
        });
      }
    });
  }

  public openStandardSection(standardSectionId: number): void {
    this.standardService.getStandardSectionById(standardSectionId).subscribe({
      next: (standardSection: StandardSectionModel) => {
        const dialogConfig: MatDialogConfig = {
          data: { ...standardSection }
        };

        const dialogRef = this.dialog.open(StandardSectionDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
          next: (result: StandardSectionModel | null) => {
            if (result) {
              let standardSectionsToUpdate: ProcessTaskStandardSectionModel[] = this.process.processTasks.flatMap(i => i.processTaskStandardSections).filter(i => i.standardSectionId == standardSectionId);
              standardSectionsToUpdate.forEach(i => {
                i.standardSectionDisplayName = result.title
              });
            }
          }
        });
      }
    });
  }

  public openProcessEmailDialog(): void {

    let model = new ProcessEmailModel();
    model.processId = this.process.id!;
    model.processGroupId = this.process.processGroupId;
    model.subject = this.process.displayValue;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...model
      },
      autoFocus: false,
    };

    this.dialog.open(ProcessEmailDialogComponent, dialogConfig);
  }
}
