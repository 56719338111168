import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CompanyModel } from '../../../models/company-model';
import { CompanyService } from '../../../services/company.service';
import { UserModel } from '../../../models/user-model';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { CompanyWrapperModel } from '../../../models/company-wrapper-model';
import { FileDocumentMetadataModel } from '../../../models/file-document-metadata-model';
import { FileDocumentService } from '../../../services/file-document-service';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { TitleService } from '../../../services/title.service';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableUtility } from '../../../utility/mat-table-utility';
import { AuthService } from '../../../services/auth.service';
import { FileService } from '../../../services/file-service';
import { ValidationService } from '../../../services/validation-service';
import { NgForm, FormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatButton, MatMiniFabButton, MatAnchor, MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader,
      MatButton, FormsModule, MatExpansionPanelTitle, MatFormField, MatLabel, MatInput, MatError,
      MatMiniFabButton, MatIcon, MatCheckbox, MatTooltip, MatTable, MatSort, MatColumnDef, MatHeaderCellDef,
      MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatAnchor, RouterLink, MatIconButton,
      MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})
export class CompanyComponent implements OnInit {
  private route = inject(ActivatedRoute);

  public company: CompanyModel;
  public logo: FileDocumentMetadataModel | null;
  public isLoading: boolean = true;
  public isBusy: boolean = false;

  public tableData = new MatTableDataSource<UserModel>([]);
  public columns: string[] = ['name', 'isVerified', 'isEnabled', 'isCompanyAdministrator', 'isEditor', 'controls'];

  private userSort: MatSort;

  @ViewChild('userTable', { read: MatSort, static: false }) set userSortValue(value: MatSort) {
    if (value) {
      this.userSort = value;
      this.tableData.sort = this.userSort;
    }
  };

  constructor(public authService: AuthService,
    private router: Router,
    private titleService: TitleService,
    private companyService: CompanyService,
    private userService: UserService,
    private toastr: ToastrService,
    private fileDocumentService: FileDocumentService,
    private fileService: FileService,
    private validationService: ValidationService) {
  }

  // TODO temp
  public isAuditsEnabled(): boolean {
    return environment.instance == "test" ||
      environment.instance == "dev" ||
      this.authService.currentUser?.companyId == 1 ||
      this.authService.currentUser?.companyId == 4;
  }

  public ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.tableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    if (id) {
      this.companyService.getCompany(id).subscribe({
        next: (response: CompanyWrapperModel) => {
          this.receiveCompanyWrapper(response);
          this.refreshTableData();
          this.isLoading = false;
        }
      });
    } else {
      this.titleService.setTitle("New Company");
      this.company = new CompanyModel();
      this.isLoading = false;
    }
  }

  public get isEditingCurrentCompany(): boolean {
    return this.authService.currentUser?.companyId == this.company.companyId;
  }

  public save(form: NgForm) {

    const isDisplayNameEmpty = !this.company.customNavItemDisplayName || this.company.customNavItemDisplayName.trim() === '';
    const isUrlEmpty = !this.company.customNavItemUrl || this.company.customNavItemUrl.trim() === '';

    if ((isDisplayNameEmpty && !isUrlEmpty) || (!isDisplayNameEmpty && isUrlEmpty)) {
      this.toastr.error("Both Custom Nav Item Display Name and Custom Nav Item URL must be either filled or empty.");
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;
      this.companyService.saveCompany(this.company).subscribe({
        next: (response: CompanyWrapperModel) => {
          this.receiveCompanyWrapper(response);
          this.toastr.success("Company saved");
          this.isBusy = false;
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public downloadLogo() {
    if (!this.isEditingCurrentCompany) {
      alert("Cannot download logo while logged into a different company.")
      return;
    }

    if (this.logo) {
      this.fileDocumentService.downloadFileDocument(this.logo).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public uploadFile(files: FileList | null) {
    if (!files || files.length != 1) {
      return;
    }

    this.isBusy = true;

    let fileToUpload = files[0] as File;
    let formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this.companyService.uploadCompanyLogo(this.company.companyId, formData).subscribe({
      next: (response: FileDocumentMetadataModel | null) => {
        this.logo = response;
        this.isBusy = false;
        this.toastr.success("Logo uploaded");
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public deleteLogo() {
    if (!this.logo) {
      return;
    }

    this.isBusy = true;
    this.companyService.deleteCompanyLogo(this.company.companyId).subscribe({
      next: () => {
        this.logo = null;
        this.isBusy = false;
        this.toastr.success("Logo deleted");
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public toggleSingleSignOn(): void {
    let message;

    if (this.company.isSingleSignOnEnabled) {
      message = "Disabling Single Sign On will require all users who have not previously setup a password to re-verify their account.";
    } else {
      message = "Enabling Single Sign On will automatically verify all users added to the system.";
    }

    message += " Any unsaved changes will be lost. Do you wish to continue?";

    if (confirm(message)) {
      this.companyService.toggleSingleSignOn(this.company.companyId).subscribe({
        next: (response: CompanyWrapperModel) => {
          this.receiveCompanyWrapper(response);
          this.refreshTableData();
          this.toastr.success(`Changes Saved`);
          this.isBusy = false;
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public get nonPrismUsers(): UserModel[] {
    return this.company.users.filter(user => !user.isPrismStaff);
  }

  public addUser() {
    if (this.nonPrismUsers.length >= this.company.userLimit && !this.authService.currentUser!.isAdministrator) {
      this.toastr.error("User Limit has been reached");
      return;
    }  else {
      this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/admin/company/${this.company.companyId}/user/0`);
    }
  }

  public importUsers(files: FileList | null) {
    if (!files || files.length != 1) {
      return;
    }

    this.isBusy = true;

    let fileToUpload = files[0] as File;

    if (!confirm(`Are you sure you want to import users from ${fileToUpload.name}?`)) {
      this.isBusy = false;
      return;
    }

    let formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this.userService.importUsers(this.company.companyId, formData).subscribe({
      next: (response) => {
        this.company.users = response;
        this.refreshTableData();
        this.isBusy = false;
        this.toastr.success("Users Imported");
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public deleteUser(user: UserModel) {
    this.isBusy = true;
    this.userService.deleteUser(user.userId!).subscribe({
      next: () => {
        this.company.users.splice(this.company.users.indexOf(user), 1);
        this.refreshTableData();
        this.isBusy = false;
        this.toastr.success("User deleted");
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public unlockUserAccount(user: UserModel) {
    this.isBusy = true;
    this.userService.unlockUserAccount(user.userId!).subscribe({
      next: () => {
        const index = this.company.users.findIndex(i => i.userId == user.userId);
        this.company.users[index].isLockedOut = false;
        this.refreshTableData();
        this.isBusy = false;
        this.toastr.success("User unlocked");
      }, error: () => {
        this.isBusy = false;
      }
    });
  }

  public resendVerificationEmail(user: UserModel) {
    this.userService.resendVerificationEmail(user).subscribe({
      next: () => {
        this.toastr.success("Verification email sent")
      }
    })
  }

  private receiveCompanyWrapper(wrapper: CompanyWrapperModel) {
    this.company = wrapper.company;
    this.logo = wrapper.companyLogo;
    this.titleService.setTitle(this.company.displayName);
  }

  private refreshTableData(): void {
    this.tableData.data = this.company.users;
  }

  public downloadTemplate() {
    const filePath = `assets/upload-template.xlsx`;
    const fileName = `User Import Template.xlsx`;
    this.fileService.downloadFile(filePath, fileName);
  }

  public exportUser(): void {
    this.isBusy = true;
    this.companyService.exportUserReport().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
