import { Component, OnInit } from '@angular/core';
import { LoginModel } from '../../models/login-model';
import { NgForm } from '@angular/forms';
import { AuthenticatedResponseModel } from '../../models/authenticated-response-model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MultiFactorAuthenticationDialogComponent } from './multi-factor-authentication-dialog/multi-factor-authentication-dialog.component';
import { AuthApiService } from '../../services/auth-api.service';
import { MultiFactorAuthenticationSubmitModel } from '../../models/multi-factor-authentication-submit-model';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  public credentials: LoginModel = { emailAddress: '', password: '' };
  public isResettingPassword: boolean = false;
  public isBusy: boolean = false;

  constructor(private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private authApiService: AuthApiService) {
  }

  public ngOnInit(): void {
  }

  public tryLogin = (form: NgForm) => {
    if (form.valid) {
      this.isBusy = true;

      let model = new MultiFactorAuthenticationSubmitModel();
      model.emailAddress = this.credentials.emailAddress;

      this.authApiService.isMultiFactorAuthenticationRequired(model).subscribe({
        next: (isMfaRequired: boolean) => {
          if (isMfaRequired) {
            const dialogConfig: MatDialogConfig = {
              data: {
                emailAddress: this.credentials.emailAddress
              }
            };

            const dialogRef = this.dialog.open(MultiFactorAuthenticationDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe({
              next: (isSuccess: boolean) => {
                if (isSuccess) {
                  this.login();
                } else {
                  this.isBusy = false;
                  return;
                }
              },
              error: () => {
                this.toastr.error("Error logging in");
                this.isBusy = false;
                return;
              }
            });
          } else {
            this.login();
          }
        },
        error: () => {
          this.isBusy = false;
        }
      })
    }
  }

  private login(): void {
    this.http.post<AuthenticatedResponseModel>(`${environment.apiUrl}auth/login`, this.credentials, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }).subscribe({
      next: (response: AuthenticatedResponseModel) => {
        this.authService.setToken(response.token);
        this.authService.setRefreshToken(response.refreshToken);

        const attemptedUrl = this.authService.getAttemptedUrl();
        this.authService.clearAttemptedUrl();

        if (attemptedUrl) {
          this.authService.setCurrentUser(response.user, true);
          this.router.navigate([attemptedUrl]);
        } else if (response.user.companies.length == 1) {
          this.authService.setCurrentUser(response.user, true);
          this.router.navigate([`${this.authService.currentUser?.companyName}`]);
        } else {
          this.authService.setCurrentUser(response.user, false);
          this.router.navigate(["/company-select"]);
        }
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public reset = (form: NgForm) => {
    if (form.valid) {
      this.isBusy = true;
      this.userService.requestPasswordReset(this.credentials).subscribe({
        next: () => {
          this.isBusy = false;
          this.toastr.success(`Password reset email sent to ${this.credentials.emailAddress}`);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public toggleReset(): void {
    this.isResettingPassword = !this.isResettingPassword;
  }
}
