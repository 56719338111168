import { Component, Inject } from "@angular/core";
import { ProcessEmailModel } from "../../../../models/process-email-model";
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { NgIf } from "@angular/common";
import { UserSelectorComponent } from "../../../../shared/user-selector/user-selector.component";
import { MatButton } from "@angular/material/button";
import { FormsModule, NgForm } from "@angular/forms";
import { ProcessService } from "../../../../services/process.service";
import { ToastrService } from "ngx-toastr";
import { UserModel } from "../../../../models/user-model";
import { UserService } from "../../../../services/user.service";
import { Observable, forkJoin } from "rxjs";
import { MultiAutocompleteComponent } from "../../../../shared/multi-autocomplete/multi-autocomplete.component";
import { AutocompleteComponent } from "../../../../shared/autocomplete/autocomplete.component";
import { ValidationService } from "../../../../services/validation-service";
import { LoadingSpinnerComponent } from "../../../../shared/loading-spinner/loading-spinner.component";

@Component({
  selector: 'app-process-email-dialog',
  templateUrl: './process-email-dialog.component.html',
  styleUrls: ['./process-email-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogActions, MatError, MatInput, MatLabel, NgIf,
    MatDialogContent, UserSelectorComponent, MatButton, FormsModule, MatFormField,
    AutocompleteComponent, MultiAutocompleteComponent, LoadingSpinnerComponent]
})
export class ProcessEmailDialogComponent {
  public processEmail: ProcessEmailModel;
  public isBusy: boolean = false;
  public isLoading: boolean = true;

  public users: Array<UserModel> = [];

  constructor(@Inject(MAT_DIALOG_DATA) private model: ProcessEmailModel,
    private processService: ProcessService,
    private toastrService: ToastrService,
    private userService: UserService,
    private validationService: ValidationService,
    private dialogRef: MatDialogRef<ProcessEmailDialogComponent>) {
    this.processEmail = structuredClone(model);
  }

  public ngOnInit(): void {
    let sources: Array<Observable<any>> = [
      this.userService.getUsers()
    ];

    forkJoin(sources).subscribe({
      next: (response) => {
        this.users = response[0] as UserModel[];
        this.isLoading = false;
      }
    })
  }

  public sendEmail(form: NgForm) {
    if (this.validationService.isFormValid(form)) {
      if (this.processEmail.recipients?.length > 0) {
        this.isBusy = true;
        this.processService.sendProcessByEmail(this.processEmail).subscribe({
          next: () => {
            this.toastrService.success("Email sent");
            this.dialogRef.close(this.processEmail);
          },
          error: () => {
            this.toastrService.error("Failed to send email");
            this.isBusy = false;
          }
        });
      } else {
        this.toastrService.error("Please select at least one recipient.");
      }
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
