import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { ConfigurationService } from './services/configuration.service';
import { FileDocumentUtility } from './utility/file-document-utility';
import { CompanyService } from './services/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'Author';
  public currentRoute: string;
  public blobUrl: string | null = null;
  private currentLogoCompanyId: number | null;

  constructor(
    public configurationService: ConfigurationService,
    public authService: AuthService,
    private companyService: CompanyService,
    private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd &&
        this.authService.isUserAuthenticated() &&
        this.authService.currentUser &&
        this.currentLogoCompanyId != this.authService.currentUser.companyId) {
            this.companyService.getCompanyLogo(this.authService.currentUser.companyId).subscribe({
              next: (response) => {
                this.currentLogoCompanyId = this.authService.currentUser?.companyId ?? null;
                this.blobUrl = FileDocumentUtility.getBlobUrl(response);
              }
            });
      }
    });
  }

  public get isTest(): boolean {
    return environment.instance === "test";
  }

  public signOut(): void {
    this.authService.clearAuthentication();
    this.router.navigate(["/sign-in"]);
  }
}
