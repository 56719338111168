import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { MultiFactorAuthenticationSubmitModel } from "../models/multi-factor-authentication-submit-model";
import { AuthenticatedResponseModel } from "../models/authenticated-response-model";
import { TokenModel } from "../models/token-model";

@Injectable({
  providedIn: 'root'
})

export class AuthApiService {
  constructor(private apiService: ApiService) {
  }

  public isMultiFactorAuthenticationRequired(model: MultiFactorAuthenticationSubmitModel): Observable<boolean> {
    return this.apiService.post(`auth/requires-mfa`, model);
  }

  public submitMultiFactorAuthenticationCode(model: MultiFactorAuthenticationSubmitModel): Observable<void> {
    return this.apiService.post(`auth/submit-mfa`, model);
  }

  public refresh(model: TokenModel): Observable<AuthenticatedResponseModel> {
    return this.apiService.post(`auth/refresh`, model)
      .pipe(map(i => new AuthenticatedResponseModel(i)));
  }
}
