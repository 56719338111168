import { Component, KeyValueDiffer, KeyValueDiffers, ViewChild, inject } from "@angular/core";
import { ProcessAuditModel } from "../../../models/process-audit-model";
import { ProcessAuditTaskModel } from "../../../models/process-audit-task-model";
import { ActivatedRoute } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { AuthService } from "../../../services/auth.service";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { ProcessAuditTaskNonConformanceModel } from "../../../models/process-audit-task-non-conformance-model";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ProcessAuditService } from "../../../services/process-audit.service";
import { ProcessAuditTaskDialogComponent } from "./process-audit-task-dialog/process-audit-task-dialog.component";
import { ProcessAuditStatusConstant } from "../../../constants/process-audit-status-constant";
import { ProcessAuditTaskNonConformanceService } from "../../../services/process-audit-task-non-conformance.service";
import { ProcessAuditClosedDialogComponent } from "./process-audit-closed-dialog/process-audit-closed-dialog.component";
import { ProcessAuditTaskNonConformanceActionModel } from "../../../models/process-audit-task-non-conformance-action-model";
import { ProcessAuditRejectionDialogComponent } from "./process-audit-rejection-dialog/process-audit-rejection-dialog.component";
import { ProcessAuditTaskNonConformanceDialogComponent } from "./process-audit-task-non-conformance-dialog/process-audit-task-non-conformance-dialog.component";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { ProcessAuditTaskNonConformanceActionDialogComponent } from "./process-audit-task-non-conformance-action-dialog/process-audit-task-non-conformance-action-dialog.component";
import { MatTableUtility } from "../../../utility/mat-table-utility";
import { environment } from "../../../../environments/environment";
import { NgIf, DatePipe } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MatCard, MatCardContent } from "@angular/material/card";
import { MatTabGroup, MatTab, MatTabChangeEvent } from "@angular/material/tabs";
import { UserSelectorComponent } from "../../../shared/user-selector/user-selector.component";
import { MatIconButton, MatButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIcon } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";
import { TruncatePipe } from "../../../utility/truncate-pipe";

@Component({
    selector: 'app-process-audit',
    templateUrl: './process-audit.component.html',
    styleUrls: ['./process-audit.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatTabGroup, MatTab, MatCardContent,
      UserSelectorComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef,
      MatCell, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow,
      MatNoDataRow, MatButton, MatSort, MatSortHeader, DatePipe, FormsModule, TruncatePipe]
})

export class ProcessAuditComponent {
  private route = inject(ActivatedRoute);
  private processAuditDiffer: KeyValueDiffer<string, any>;

  public isLoading: boolean = false;
  public isBusy: boolean = false;
  public isRefreshingTableData = false;
  public processAudit: ProcessAuditModel;

  public processAuditTaskTableData = new MatTableDataSource<ProcessAuditTaskModel>([]);
  public processAuditColumns: string[] = ['sequenceNumber', 'title', 'responsible', 'description', 'controls'];

  public processAuditTaskNonConformances: Array<ProcessAuditTaskNonConformanceModel> = [];
  public processAuditTaskNonConformanceTableData = new MatTableDataSource<ProcessAuditTaskNonConformanceModel>([]);
  public processAuditTaskNonConformanceColumns: string[] = ['reference', 'title', 'type', 'reportedBy', 'reportedDate', 'status'];

  public actions: Array<ProcessAuditTaskNonConformanceActionModel> = [];
  public actionTableData = new MatTableDataSource<ProcessAuditTaskNonConformanceActionModel>([]);
  public actionColumns: string[] = ['reference', 'description', 'type', 'assignedToUser', 'dueDate', 'status'];

  private nonConformanceSort: MatSort;
  private actionSort: MatSort;

  private auditTabIndex = 0;
  private nonConformancesTabIndex = 1;
  private actionsTabIndex = 2;

  @ViewChild('processAuditTaskNonConformanceTable', { read: MatSort, static: false }) set processAuditNonConformance(value: MatSort) {
    if (value) {
      this.nonConformanceSort = value;
      this.processAuditTaskNonConformanceTableData.sort = this.nonConformanceSort
    }
  };

  @ViewChild('actionTable', { read: MatSort, static: false }) set NonConformanceAction(value: MatSort) {
    if (value) {
      this.actionSort = value;
      this.actionTableData.sort = this.actionSort
    }
  }

  constructor(public authService: AuthService,
    private processAuditService: ProcessAuditService,
    private processAuditTaskNonConformanceService: ProcessAuditTaskNonConformanceService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private differs: KeyValueDiffers) {
  }

  public ngOnInit(): void {
    // TODO Temp
    if (environment.instance != "test" &&
      environment.instance != "dev" &&
      this.authService.currentUser?.companyId != 1
      && this.authService.currentUser?.companyId != 4) {
      return;
    }

    this.isLoading = true;
    const processAuditId = Number(this.route.snapshot.paramMap.get('auditId'));

    this.processAuditTaskNonConformanceTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.actionTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    let sources: Array<Observable<any>> = [
      this.processAuditService.getProcessAuditById(processAuditId),
      this.processAuditTaskNonConformanceService.getProcessAuditTaskNonConformancesByProcessAuditId(processAuditId),
      this.processAuditTaskNonConformanceService.getProcessAuditTaskNonConformanceActionsByProcessAuditId(processAuditId),
    ];

    forkJoin(sources).subscribe({
      next: (response) => {
        this.receiveProcessAudit(response[0]);
        this.processAuditTaskNonConformances = response[1] as ProcessAuditTaskNonConformanceModel[];
        this.actions = response[2] as ProcessAuditTaskNonConformanceActionModel[];
        this.refreshTableData();
        this.isLoading = false;
      }
    });
  }

  public getStatusDescription(statusCode: string): string {
    const status = ProcessAuditStatusConstant.ValuesWithDescriptions.find(i => i.value === statusCode);
    return status ? status.description : statusCode;
  }

  public canSubmitForClosure(): boolean {
    return this.processAudit.closureApproverUser?.userId != null &&
      this.processAudit.status === ProcessAuditStatusConstant.Open &&
      this.canEdit();
  }

  public canEdit(): boolean {
    return this.processAudit.status == ProcessAuditStatusConstant.Open &&
      this.authService.canCurrentUserAudit;
  }

  public canClose() {
    return this.processAudit.status === ProcessAuditStatusConstant.PendingClosure &&
      (this.authService.currentUser?.isAdministrator ||
        this.authService.currentUser?.isCompanyAdministrator ||
        this.authService.currentUser?.userId == this.processAudit.closureApproverUser?.userId);
  }

  public isClosed() {
    return this.processAudit.status === ProcessAuditStatusConstant.Closed;
  }

  public hasRejectionComment() {
    return this.processAudit.rejectionComment;
  }

  public save(): void {
    if (!this.authService.canCurrentUserAudit) {
      return;
    }

    this.isBusy = true;

    this.processAuditService.saveProcessAudit(this.processAudit).subscribe({
      next: (response: ProcessAuditModel) => {
        this.receiveProcessAudit(response);
        this.toastr.success("Audit saved");
        this.isBusy = false;
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public trySubmitForClosure() {
    if (!confirm("Are you sure you want to submit this Audit for closure? Changes cannot be made once submitted")) {
      return;
    }

    this.isBusy = true;

    if (this.processAuditDiffer.diff(this.processAudit)) {
      this.processAuditService.saveProcessAudit(this.processAudit).subscribe({
        next: (response: ProcessAuditModel) => {
          this.receiveProcessAudit(response);
          this.submitForClosure();
        },
        error: () => {
          this.isBusy = false;
        }
      });
    } else {
      this.submitForClosure();
    }
  }

  private submitForClosure(): void {
    this.processAuditService.submitForClosure(this.processAudit.processAuditId).subscribe({
      next: () => {
        this.refreshProcessAuditDetails();
        this.toastr.success("Audit submitted for closure");
        this.isBusy = false;
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public rejectClosure() {
    const dialogRef = this.dialog.open(ProcessAuditRejectionDialogComponent, {
      data: this.processAudit
    });

    dialogRef.afterClosed().subscribe({
      next: () => {
        this.refreshProcessAuditDetails();
      }
    })
  }

  public closeAudit() {
    const dialogRef = this.dialog.open(ProcessAuditClosedDialogComponent, {
      data: this.processAudit
    });

    dialogRef.afterClosed().subscribe({
      next: () => {
        this.refreshProcessAuditDetails();
      }
    })
  }

  public openProcessAuditTaskDialog(task: ProcessAuditTaskModel): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...task,
      }
    };

    const dialogRef = this.dialog.open(ProcessAuditTaskDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskModel) => {
        if (result) {
          const index = this.processAudit.processAuditTasks.findIndex(i => i.processAuditTaskId == result.processAuditTaskId);
          if (index != -1) {
            this.processAudit.processAuditTasks[index] = result;
            this.processAuditTaskTableData.data = this.processAudit.processAuditTasks;
          }
        }
      }
    });
  }

  public deleteProcessAuditTaskNonConformance(processAuditTaskNonConformance: ProcessAuditTaskNonConformanceModel) {
    if (!confirm("Are you sure you want to delete this Non Conformance and any associated Actions?")) {
      return;
    }

    this.processAuditTaskNonConformanceService.deleteProcessAuditTaskNonConformance(processAuditTaskNonConformance.processAuditTaskNonConformanceId).subscribe({
      next: () => {
        this.processAuditTaskNonConformances.splice(this.processAuditTaskNonConformances.indexOf(processAuditTaskNonConformance), 1);
        this.refreshTableData();
        this.toastr.success("Non Conformance deleted.");
      }
    })
  }

  private refreshProcessAuditDetails() {
    forkJoin([
      this.processAuditService.getProcessAuditById(this.processAudit.processAuditId),
      this.processAuditTaskNonConformanceService.getProcessAuditTaskNonConformancesByProcessAuditId(this.processAudit.processAuditId)
    ]).subscribe({
      next: (response) => {
        this.receiveProcessAudit(response[0]);
        this.processAuditTaskNonConformances = response[1] as ProcessAuditTaskNonConformanceModel[];
        this.refreshTableData();
      },
    });
  }

  public editProcessAuditTaskNonConformance(processAuditTaskNonConformance: ProcessAuditTaskNonConformanceModel): void {
    const dialogConfig: MatDialogConfig = {
      data: processAuditTaskNonConformance
    };

    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceModel) => {
        if (result) {
          const index = this.processAuditTaskNonConformances.findIndex(i => i.processAuditTaskNonConformanceId == result.processAuditTaskNonConformanceId);
          this.processAuditTaskNonConformances[index] = result;
          this.refreshTableData();
        }
      }
    })
  }

  public editProcessAuditTaskNonConformanceAction(processAuditTaskNonConformanceAction: ProcessAuditTaskNonConformanceActionModel) {
    const dialogConfig: MatDialogConfig = {
      data: processAuditTaskNonConformanceAction
    };

    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceActionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceActionModel) => {
        if (result) {
          const index = this.actions.findIndex(i => i.processAuditTaskNonConformanceActionId === result.processAuditTaskNonConformanceActionId);
          this.actions[index] = result;
          this.refreshTableData();
        }
      }
    })
  }

  public onTabChange(event: MatTabChangeEvent) {
    this.isRefreshingTableData = true;
    if (event.index == this.nonConformancesTabIndex) {
      this.processAuditTaskNonConformanceService.getProcessAuditTaskNonConformancesByProcessAuditId(this.processAudit.processAuditId).subscribe({
        next: (response: Array<ProcessAuditTaskNonConformanceModel>) => {
          this.processAuditTaskNonConformances = response;
          this.refreshTableData();
          this.isRefreshingTableData = false;
        }, error: () => {
          this.isRefreshingTableData = false;
        }
      });
    } else if (event.index == this.actionsTabIndex) {
      this.processAuditTaskNonConformanceService.getProcessAuditTaskNonConformanceActionsByProcessAuditId(this.processAudit.processAuditId).subscribe({
        next: (response: Array<ProcessAuditTaskNonConformanceActionModel>) => {
          this.actions = response;
          this.refreshTableData();
          this.isRefreshingTableData = false;
        }, error: () => {
          this.isRefreshingTableData = false;
        }
      });
    } else {
      this.isRefreshingTableData = false;
    }
  }

  private receiveProcessAudit(model: ProcessAuditModel): void {
    this.processAudit = model;
    this.processAuditTaskTableData.data = this.processAudit.processAuditTasks;
    this.processAuditDiffer = this.differs.find(this.processAudit).create();
  }

  private refreshTableData(): void {
    this.processAuditTaskNonConformanceTableData.data = this.processAuditTaskNonConformances;
    this.actionTableData.data = this.actions;
  }
}
