<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()" mat-flat-button color="accent" class="button accent-button" (click)="createProcessGroup()">Add Process Group</button>
            <button mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/process-register" color="accent" class="button accent-button">Process Register</button>
            <button mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/revision-register" color="accent" class="button accent-button">Revision Register </button>
            <button *ngIf="isAuditsEnabled()" mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/audit-dashboard" color="accent" class="button accent-button">Audit Dashboard</button>
          </div>

          <table #processGroupTable mat-table [dataSource]="processGroupTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let processGroup">
                <button mat-flat-button
                        [ngClass]="{ 'row-highlight': selectedProcessGroup?.processGroupId == processGroup.processGroupId }"
                        (click)="selectProcessGroup(processGroup)"
                        class="table-button">
                  <div>
                    {{processGroup.title}}
                    <mat-icon *ngIf="processGroup.isVisibleOnlyToEditors" inline="true" color="accent" matTooltip="This group and it's processes are only visible to Editors.">disabled_visible</mat-icon>
                  </div>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let processGroup" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editProcessGroup(processGroup)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteProcessGroup(processGroup)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processGroupColumns"></tr>
            <tr mat-row
                [ngClass]="{ 'row-highlight': selectedProcessGroup?.processGroupId == processGroup.processGroupId }"
                *matRowDef="let processGroup; columns: processGroupColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="processGroupColumns.length" class="no-data-row">
                No process groups found
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-menu-container">
            <div class="list-button-row">
              <button *ngIf="canEdit()"
                      mat-flat-button
                      color="accent"
                      class="button accent-button"
                      [disabled]="selectedProcessGroup == null"
                      (click)="createProcess()">
                Add Process
              </button>
            </div>
            <div>
              <button mat-icon-button color="accent" [matMenuTriggerFor]="documentMenu">
                <mat-icon>filter_list</mat-icon>
              </button>
              <mat-menu #documentMenu="matMenu">
                <div mat-menu-item class="checkbox-menu-item" disableRipple>
                  <mat-checkbox (click)="$event.stopPropagation();"
                                [(ngModel)]="showProcessReference"
                                (change)="setProcessTitleColumn()">Show Reference</mat-checkbox>
                </div>
              </mat-menu>
            </div>
          </div>

          <table #processTable mat-table [dataSource]="processTableData" matSort [matSortActive]="processColumns[0]" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="referenceAndTitle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="referenceAndTitle">Title</th>
              <td mat-cell *matCellDef="let process">
                <button mat-flat-button
                        (click)="editProcess(process)"
                        class="button table-button">
                  {{process.referenceAndTitle}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="title">Title</th>
              <td mat-cell *matCellDef="let process">
                <button mat-flat-button
                        (click)="editProcess(process)"
                        class="button table-button">
                  {{process.title}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let process" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editProcess(process)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteProcess(process)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="canEdit() && !singleGroup()"
                        mat-icon-button
                        color="accent"
                        (click)="swapProcessGroup(process)"
                        matTooltip="Change Group">
                  <mat-icon>swap_horiz</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: processColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="processColumns.length" class="no-data-row">
                {{ selectedProcessGroup == null ? 'No group selected' : 'No processes found for the selected group' }}
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
